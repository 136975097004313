import { getFirstItem } from "lib/helpers";
import { useAuth } from "./use-auth";
import { useCheckIn } from "./use-checkIn";
import { useCheckOut } from "./use-checkOut";
import {
  createFetchOptions,
  fetchHandler,
  useFetch,
  useMutate,
  useQueryClient,
} from "./use-fetch";
import { useProperty } from "./use-property";

import { Reservation } from "@kohost/api-client/models/reservation";

import {
  AssignSpaceToReservationCommand,
  CheckInReservationCommand,
  CheckOutReservationCommand,
  DescribeReservationCommand,
  DescribeReservationEarlyCheckInProductsCommand,
  DescribeReservationLateCheckOutProductsCommand,
  DescribeReservationPetProductsCommand,
  DescribeReservationPromosCommand,
  DescribeReservationRoomUpgradesCommand,
  ListMyReservationsCommand,
  PurchaseReservationEarlyCheckInProductsCommand,
  PurchaseReservationLateCheckOutProductsCommand,
  PurchaseReservationPetProductsCommand,
  PurchaseReservationPromosCommand,
  PurchaseReservationRoomUpgradesCommand,
  SendPreArrivalSMSCommand,
  SendRoomControlSMSCommand,
  UpdateReservationExpectedArrivalTimeCommand,
} from "@kohost/api-client/useCases";

const RES_QUERY_KEY = "reservations";
const SPACE_QUERY_KEY = "spaces";

function sortByCheckInDate(a, b) {
  return new Date(a.checkInDateTime) - new Date(b.checkInDateTime);
}

export const useReservations = (opts = { enable: true }) => {
  const { currentPropertyId } = useProperty();

  const fetchOptions = createFetchOptions(currentPropertyId, RES_QUERY_KEY);
  const RESERVATIONS = fetchOptions.key;
  const headers = fetchOptions.headers;

  const resQuery = useFetch({
    queryFn: fetchHandler({
      useCase: new ListMyReservationsCommand({
        headers,
      }),
    }),
    queryKey: RESERVATIONS,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    meta: {
      cacheById: true,
    },
    enabled: opts.enable,
  });

  const allReservations = resQuery.data || [];

  const reserved = allReservations
    .filter((r) => r.status === "reserved")
    .sort(sortByCheckInDate);
  const checkedIn = allReservations
    .filter((r) => r.status === "checkedIn")
    .sort(sortByCheckInDate);
  const cancelled = allReservations
    .filter((r) => r.status === "cancelled")
    .sort(sortByCheckInDate);
  const checkedOut = allReservations
    .filter((r) => r.status === "checkedOut")
    .sort(sortByCheckInDate);

  const upcoming = reserved.filter((r) => {
    const now = new Date();
    const checkIn = new Date(r.checkInDateTime);
    const checkOut = new Date(r.checkOutDateTime);
    const diff = checkIn.getTime() - now.getTime();
    const hours = diff / (1000 * 60 * 60);
    return now < checkOut && hours <= 24 && hours >= -24;
  });

  return {
    ...resQuery,
    all: allReservations,
    reserved,
    checkedIn,
    checkedOut,
    cancelled,
    upcoming,
  };
};

export const useReservation = (id, opts = { fetchExtraData: true }) => {
  const { data: currentUser } = useAuth();
  const { currentPropertyId } = useProperty();

  const resFetchOptions = createFetchOptions(currentPropertyId, RES_QUERY_KEY);
  const spaceFetchOptions = createFetchOptions(
    currentPropertyId,
    SPACE_QUERY_KEY,
  );
  const RESERVATIONS = resFetchOptions.key;
  const SPACES = spaceFetchOptions.key;
  const headers = resFetchOptions.headers;

  const queryClient = useQueryClient();

  const updateReservationQueryCache = (reservation) => {
    queryClient.setQueryData([...RESERVATIONS, reservation.id], (cache) => {
      if (!cache) return new Reservation(reservation);
      return new Reservation({
        ...cache,
        ...reservation,
      });
    });

    queryClient.setQueriesData({ queryKey: RESERVATIONS }, (cache) => {
      if (!cache) return [reservation];
      if (Array.isArray(cache)) {
        const found = cache.find((r) => r.id === reservation.id);
        if (!found && reservation.id) return [...cache, reservation];
        return cache.map((r) => {
          if (r.id === reservation.id) {
            return new Reservation({
              ...r,
              ...reservation,
            });
          }
          return r;
        });
      } else if (cache.id === reservation.id) {
        return new Reservation({
          ...cache,
          ...reservation,
        });
      }
      return cache;
    });
  };

  const resQuery = useFetch({
    queryFn: fetchHandler({
      useCase: new DescribeReservationCommand({
        data: { id },
        headers,
      }),
      options: { transformResponse: getFirstItem },
    }),
    queryKey: [...RESERVATIONS, id],
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(id),
    refetchOnWindowFocus: true,
    meta: {
      cacheById: true,
    },
  });

  const reservation = resQuery?.data;

  const { checkInConfig } = useCheckIn({ user: currentUser, reservation });
  const { checkOutConfig } = useCheckOut();

  const earlyCheckInEnabled = Boolean(checkInConfig?.earlyCheckIn);
  const roomUpgradesEnabled = Boolean(checkInConfig?.roomUpgrades);
  const lateCheckOutEnabled = Boolean(checkOutConfig?.lateCheckOut);
  const petEnabled = Boolean(checkInConfig?.pet);
  const promoEnabled = Boolean(checkInConfig?.promo);

  const reservationReserved = reservation?.status === "reserved";
  const reservationCheckedIn = reservation?.status === "checkedIn";

  const enableEarlyCheckInQuery = Boolean(
    reservationReserved && earlyCheckInEnabled && opts.fetchExtraData,
  );

  const earlyCheckInOptionsQuery = useFetch({
    queryFn: fetchHandler({
      useCase: new DescribeReservationEarlyCheckInProductsCommand({
        data: { id },
        headers,
      }),
    }),
    queryKey: ["products", ...RESERVATIONS, id, "earlyCheckInProducts"],
    enabled: enableEarlyCheckInQuery,
    retry: 1,
  });

  const purchaseReservationEarlyCheckInProducts = (vars) => {
    const handler = fetchHandler({
      useCase: new PurchaseReservationEarlyCheckInProductsCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const purchaseEarlyCheckInMutation = useMutate({
    mutationFn: purchaseReservationEarlyCheckInProducts,
    onSuccess: (reservation) => {
      queryClient.invalidateQueries({
        queryKey: ["products", ...RESERVATIONS, id, "earlyCheckInProducts"],
        exact: true,
      });
      updateReservationQueryCache(reservation);
    },
  });

  const enableLateCheckOutQuery = Boolean(
    reservationCheckedIn && lateCheckOutEnabled && opts.fetchExtraData,
  );

  const lateCheckOutOptions = useFetch({
    queryFn: fetchHandler({
      useCase: new DescribeReservationLateCheckOutProductsCommand({
        data: { id },
        headers,
      }),
    }),
    queryKey: ["products", ...RESERVATIONS, id, "lateCheckOutProducts"],
    enabled: enableLateCheckOutQuery,
    retry: 1,
  });

  const purchaseReservationLateCheckOutProducts = (vars) => {
    const handler = fetchHandler({
      useCase: new PurchaseReservationLateCheckOutProductsCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const purchaseLateCheckOutMutation = useMutate({
    mutationFn: purchaseReservationLateCheckOutProducts,
    retry: false,
    onSuccess: (reservation) => {
      queryClient.invalidateQueries({
        queryKey: ["products", ...RESERVATIONS, id, "lateCheckOutProducts"],
        exact: true,
      });
      updateReservationQueryCache(reservation);
    },
  });

  const enableRoomUpgradesQuery = Boolean(
    reservationReserved && roomUpgradesEnabled && opts.fetchExtraData,
  );

  const roomUpgradeOptionsQuery = useFetch({
    queryKey: ["products", ...RESERVATIONS, id, "roomUpgrades"],
    queryFn: fetchHandler({
      useCase: new DescribeReservationRoomUpgradesCommand({
        data: { id },
        headers,
      }),
      options: { transformResponse: getFirstItem },
    }),
    enabled: enableRoomUpgradesQuery,
    retry: 1,
  });

  const purchaseReservationRoomUpgrades = (vars) => {
    const handler = fetchHandler({
      useCase: new PurchaseReservationRoomUpgradesCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const purchaseRoomUpgradeMutation = useMutate({
    mutationFn: purchaseReservationRoomUpgrades,
    retry: false,
    onSuccess: (reservation) => {
      updateReservationQueryCache(reservation);
    },
  });

  const enablePetQuery = Boolean(
    reservationReserved && petEnabled && opts.fetchExtraData,
  );

  const petFeeOptionsQuery = useFetch({
    queryKey: ["products", ...RESERVATIONS, id, "pet"],
    queryFn: fetchHandler({
      useCase: new DescribeReservationPetProductsCommand({
        data: { id },
        headers,
      }),
    }),
    retry: false,
    enabled: enablePetQuery,
  });

  const purchasePetFee = (vars) => {
    const handler = fetchHandler({
      useCase: new PurchaseReservationPetProductsCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const purchasePetFeeMutation = useMutate({
    mutationFn: purchasePetFee,
    retry: false,
    onSuccess: (reservation) => {
      queryClient.invalidateQueries({
        queryKey: SPACES,
      });
      updateReservationQueryCache(reservation);
    },
  });

  const enablePromoQuery = Boolean(
    reservationReserved && promoEnabled && opts.fetchExtraData,
  );

  const promoOptionsQuery = useFetch({
    queryKey: ["products", ...RESERVATIONS, id, "promos"],
    queryFn: fetchHandler({
      useCase: new DescribeReservationPromosCommand({
        data: { id },
        headers,
      }),
    }),
    enabled: enablePromoQuery,
  });

  const purchasePromos = (vars) => {
    const handler = fetchHandler({
      useCase: new PurchaseReservationPromosCommand({
        data: vars,
        headers,
      }),
    });
    return handler();
  };

  const purchasePromosMutation = useMutate({
    mutationFn: purchasePromos,
    retry: false,
  });

  const checkInReservation = (vars) => {
    const handler = fetchHandler({
      useCase: new CheckInReservationCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const checkInMutation = useMutate({
    mutationFn: checkInReservation,
    retry: false,
    onSuccess: (reservation) => {
      queryClient.invalidateQueries({
        queryKey: SPACES,
      });
      updateReservationQueryCache(reservation);
    },
  });

  const checkOutReservation = (vars) => {
    const handler = fetchHandler({
      useCase: new CheckOutReservationCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const checkOutMutation = useMutate({
    mutationFn: checkOutReservation,
    retry: false,
    onSuccess: (reservation) => {
      updateReservationQueryCache(reservation);
    },
  });

  const assignSpaceToReservation = (vars) => {
    const handler = fetchHandler({
      useCase: new AssignSpaceToReservationCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const assignSpaceMutation = useMutate({
    mutationFn: assignSpaceToReservation,
    onSuccess: ({ reservation }) => {
      updateReservationQueryCache(reservation);
    },
  });

  const updateReservationExpectedArrivalTime = (vars) => {
    const handler = fetchHandler({
      useCase: new UpdateReservationExpectedArrivalTimeCommand({
        data: vars,
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const updateReservationExpectedArrivalTimeMutation = useMutate({
    mutationFn: updateReservationExpectedArrivalTime,
    onSuccess: (reservation) => {
      updateReservationQueryCache(reservation);
    },
  });

  const sendReservationRoomControlSMS = (vars) => {
    const handler = fetchHandler({
      useCase: new SendRoomControlSMSCommand({
        data: vars,
        query: { force: true },
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const sendRoomControlSMSMutation = useMutate({
    mutationFn: sendReservationRoomControlSMS,
    retry: false,
  });

  const sendPreArrivalSMS = (vars) => {
    const handler = fetchHandler({
      useCase: new SendPreArrivalSMSCommand({
        data: vars,
        query: { force: true },
        headers,
      }),
      options: { transformResponse: getFirstItem },
    });
    return handler();
  };

  const sendPreArrivalSMSMution = useMutate({
    mutationFn: sendPreArrivalSMS,
    retry: false,
  });

  return {
    ...resQuery,
    earlyCheckIn: earlyCheckInOptionsQuery,
    lateCheckOut: lateCheckOutOptions,
    roomUpgrades: roomUpgradeOptionsQuery,
    petFees: petFeeOptionsQuery,
    promos: promoOptionsQuery,
    purchasePromos: purchasePromosMutation,
    purchasePetFees: purchasePetFeeMutation,
    purchaseEarlyCheckIn: purchaseEarlyCheckInMutation,
    purchaseLateCheckOut: purchaseLateCheckOutMutation,
    purchaseRoomUpgrade: purchaseRoomUpgradeMutation,
    checkInReservation: checkInMutation,
    checkOutReservation: checkOutMutation,
    assignSpaceToReservation: assignSpaceMutation,
    updateReservationExpectedArrivalTime:
      updateReservationExpectedArrivalTimeMutation,
    sendRoomControlSMS: sendRoomControlSMSMutation,
    sendPreArrivalSMS: sendPreArrivalSMSMution,
    updateReservationQueryCache,
  };
};
