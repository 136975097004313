import { toast } from "@/components/ui/toast";
import { useCallback, useEffect } from "react";

export const AppUpdater = () => {
  const handleUpdate = useCallback((registration) => {
    const newWorker = registration.waiting;

    if (newWorker) {
      toast("App Update Available", {
        duration: Infinity,
        description:
          "A new version of the app is available. Please reload to update.",
        action: {
          label: "Update",
          onClick: () => {
            window.location.reload();
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      // Check for waiting workers on load
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.waiting) {
          handleUpdate(registration);
        }

        // Listen for new updates
        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;

          if (newWorker) {
            newWorker.addEventListener("statechange", () => {
              if (
                newWorker.state === "installed" &&
                navigator.serviceWorker.controller
              ) {
                handleUpdate(registration);
              }
            });
          }
        });

        // Check for updates periodically
        const interval = setInterval(
          () => {
            registration.update();
          },
          1000 * 60 * 15,
        );

        return () => clearInterval(interval);
      });
    }
  }, [handleUpdate]);

  return null;
};
