import { getHexColorRange } from "../string/colors";

export function createAppManifest(manifest) {
  if (manifest) {
    manifest.scope = window.location.origin;

    const content = JSON.stringify(manifest);
    const manifestBlob = new Blob([content], {
      type: "application/manifest+json",
    });
    const url = URL.createObjectURL(manifestBlob);
    document.getElementById("manifest")?.setAttribute("href", url);
  }
}

export function createAppBranding(manifest) {
  if (manifest) {
    const appName = manifest.name;
    const appIcons = manifest.icons;
    const themeColor = manifest.theme_color;
    const appIcon = appIcons && appIcons[0] && appIcons[0].src;
    if (appIcon) {
      document.getElementById("favicon")?.setAttribute("href", appIcon);
      document
        .getElementById("apple-touch-icon")
        ?.setAttribute("href", appIcon);
    }

    if (themeColor) {
      document
        .getElementById("theme-color")
        ?.setAttribute("content", themeColor);
    }

    if (appName) {
      // replace Kohost in the title with the app name
      document.title = document.title?.replace(
        "Kohost",
        appName + " powered by Kohost",
      );
    }
  }
}

export function transitionThemeColor(themeColor, duration = 1000) {
  // get head meta with id of "theme-color"
  const themeColorMeta = document.querySelector('meta[name="theme-color"]');

  const currentColor = themeColorMeta.getAttribute("content");

  const colorRange = getHexColorRange(currentColor, themeColor);

  const length = colorRange.length;
  const interval = duration / length;

  // loop through the color range and set the theme color
  colorRange.forEach((color, i) => {
    setTimeout(() => {
      themeColorMeta.setAttribute("content", color);
    }, i * interval);
  });
}
