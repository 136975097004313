import LoadingSpinner from "components/common/LoadingSpinner";
import {
  getUserOrganizationPermissions as getOrgPermissions,
  getUserPropertyPermissions as getPropertyPermissions,
  getRolesFromPermissions,
  sortRoles,
} from "lib/auth/roles";
import { isIOS } from "lib/browser/device";
import { getFirstItem } from "lib/helpers";
import React, { createContext, useContext } from "react";
import {
  createFetchOptions,
  fetchHandler,
  useFetch,
  useMutate,
  useQueryClient,
} from "./use-fetch";
import { useLocalStorage } from "./use-local-storage";
import { usePasskey } from "./use-passkey";
import { useProperty } from "./use-property";
import { useReservations } from "./use-reservations";
import { useSpaces } from "./use-spaces";

import {
  CheckVerificationCodeCommand,
  DescribeMyAuthCommand,
  DescribeSelfCommand,
  LoginFinishCommand,
  LoginStartCommand,
  LogoutSelfCommand,
  RequestLoginTokenCommand,
  SendVerificationCodeCommand,
  UpdateSelfCommand,
} from "@kohost/api-client/useCases";

const authContext = createContext({});

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  const location = window.location.pathname;
  const isLoginPage = location.includes("/login");
  const showLoader = isLoginPage ? false : auth.loading;
  if (showLoader) return <LoadingSpinner fullScreen height={36} width={36} />;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const {
    currentPropertyId: propertyId,
    updateManifest,
    organization,
    organizationId,
    isHospitality: propertyIsHospitality,
    changeOrganization,
  } = useProperty();

  const queryClient = useQueryClient();

  const enableAuthQuery = Boolean(!organizationId);

  const fetchOptions = createFetchOptions(propertyId, "auth");

  const { data: auth } = useFetch({
    queryFn: fetchHandler({
      useCase: new DescribeMyAuthCommand({
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    }),
    queryKey: ["auth"],
    enabled: enableAuthQuery,
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: true,
    retry: false,
  });

  if (auth?.organizationId && auth.organizationId !== organizationId) {
    changeOrganization(auth.organizationId);
  }

  const userQuery = useFetch({
    queryFn: async () => {
      const handler = fetchHandler({
        useCase: new DescribeSelfCommand({
          query: propertyIsHospitality ? { includeRevenue: true } : null,
          headers: fetchOptions.headers,
        }),
        options: {
          transformResponse: getFirstItem,
        },
      });

      const self = await handler();

      const permissionsHere = getOrgPermissions(self, organizationId);
      self.permissions = permissionsHere;

      return self;
    },
    queryKey: ["currentUser"],
    enabled: Boolean(organizationId),
    refetchOnWindowFocus: () => {
      // disable on login page
      return window.location.pathname !== "/login";
    },
    retry: 1,
  });

  const enablePwaTokenQuery = Boolean(isIOS() && userQuery?.data);

  const pwaTokenQuery = useFetch({
    queryFn: fetchHandler({
      useCase: new RequestLoginTokenCommand({
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    }),
    queryKey: ["pwaToken"],
    staleTime: 1000 * 60 * 15, // 15 minutes
    enabled: enablePwaTokenQuery,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const token = pwaTokenQuery?.data?.token;
  const orgManifest = organization?.appManifest;

  if (token && orgManifest) {
    const startUrl = window.location.origin + "/?pwaToken=" + token;
    orgManifest.start_url = startUrl;
    updateManifest(orgManifest);
  }

  const currentUser = userQuery?.data;

  const currentUserPropertyPermissions = getPropertyPermissions(
    currentUser,
    organizationId,
    propertyId,
  );

  const userRoles = getRolesFromPermissions(
    currentUserPropertyPermissions,
  ).sort(sortRoles);

  const selectedRoleKey = `property-${propertyId}-selectedRole`;

  const [currentUserRole, setCurrentUserRole] = useLocalStorage(
    selectedRoleKey,
    userRoles[0],
  );

  if (userRoles.length > 0 && propertyId) {
    if (!currentUserRole) setCurrentUserRole(userRoles[0]);
    else {
      const roleExists = userRoles.includes(currentUserRole);
      if (!roleExists) setCurrentUserRole(userRoles[0]);
    }
  }

  const isGuest = currentUserRole === "Guest";

  const { upcoming: upcomingReservations, checkedIn: checkedInReservations } =
    useReservations({
      enable: Boolean(currentUser && isGuest),
    });

  const userIsCheckedIn = checkedInReservations?.length > 0;

  const updateSelf = (vars) => {
    const handler = fetchHandler({
      useCase: new UpdateSelfCommand({
        data: vars,
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    });
    return handler();
  };

  const sendVerificationCode = (vars) => {
    const handler = fetchHandler({
      useCase: new SendVerificationCodeCommand({
        data: vars,
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    });
    return handler();
  };

  const checkVerificationCode = (vars) => {
    const handler = fetchHandler({
      useCase: new CheckVerificationCodeCommand({
        data: vars,
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    });
    return handler();
  };

  const loginStart = (vars) => {
    const headers = createFetchOptions(null, "auth").headers;
    const handler = fetchHandler({
      useCase: new LoginStartCommand({
        data: vars.data,
        query: vars.query,
        headers: headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    });
    return handler();
  };

  const loginFinish = (vars) => {
    const handler = fetchHandler({
      useCase: new LoginFinishCommand({
        data: vars.data,
        query: vars.query,
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    });
    return handler();
  };

  const logout = () => {
    const handler = fetchHandler({
      useCase: new LogoutSelfCommand({
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    });
    return handler();
  };

  const requestLoginToken = () => {
    const handler = fetchHandler({
      useCase: new RequestLoginTokenCommand({
        headers: fetchOptions.headers,
      }),
      options: {
        transformResponse: getFirstItem,
      },
    });
    return handler();
  };

  const selfMutation = useMutate({
    mutationFn: updateSelf,
    onSuccess: (user) => {
      const permissionsHere = getOrgPermissions(user, organizationId);
      user.permissions = permissionsHere;
      queryClient.setQueryData(["currentUser"], user);
    },
  });

  const sendVerificationCodeMutation = useMutate({
    mutationFn: sendVerificationCode,
  });

  const checkVerificationCodeMutation = useMutate({
    mutationFn: checkVerificationCode,
  });

  const finishLogin = useMutate({
    mutationFn: loginFinish,
  });

  const startLogin = useMutate({
    mutationFn: loginStart,
  });

  const getTokenMutation = useMutate({
    mutationFn: requestLoginToken,
  });

  const logoutMutation = useMutate({
    mutationFn: logout,
  });

  const enableSpacesQuery =
    currentUser && Boolean((isGuest && userIsCheckedIn) || !isGuest);

  const spaces = useSpaces(
    {},
    {
      enable: enableSpacesQuery ? true : false,
      fetchRooms: enableSpacesQuery && !propertyIsHospitality,
    },
  );

  const { register: registerPasskey, getSavedRegistations } = usePasskey();

  return {
    ...userQuery,
    propertyPermissions: currentUserPropertyPermissions,
    currentRole: currentUserRole,
    roles: userRoles,
    changeRole: setCurrentUserRole,
    pwaToken: pwaTokenQuery?.data,
    updateSelf: selfMutation,
    sendVerificationCode: sendVerificationCodeMutation,
    checkVerificationCode: checkVerificationCodeMutation,
    spaces,
    isCheckedIn: userIsCheckedIn,
    upcomingReservations,
    checkedInReservations,
    loading: userQuery?.isLoading,
    registerPasskey,
    getSavedPasskeys: getSavedRegistations,
    startLogin,
    finishLogin,
    getToken: getTokenMutation,
    logout: logoutMutation,
  };
};
