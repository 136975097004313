export function getHexColorRange(startColor, endColor) {
  // convert the hex color strings to RGB values
  const startRGB = hexToRgb(startColor);
  const endRGB = hexToRgb(endColor);

  // calculate the step size for each color component
  const stepR = (endRGB.r - startRGB.r) / 255;
  const stepG = (endRGB.g - startRGB.g) / 255;
  const stepB = (endRGB.b - startRGB.b) / 255;

  // create an array to store the resulting hex colors
  const hexColors = new Set();

  // loop through each step and convert the RGB values back to hex
  for (let i = 0; i <= 255; i++) {
    const r = Math.round(startRGB.r + i * stepR);
    const g = Math.round(startRGB.g + i * stepG);
    const b = Math.round(startRGB.b + i * stepB);
    hexColors.add(rgbToHex(r, g, b));
  }

  return Array.from(hexColors);
}

export function hexToRgb(hexColor) {
  const hex = hexColor.replace("#", "");
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  };
}

export function rgbToHex(r, g, b) {
  const hexR = r.toString(16).padStart(2, "0");
  const hexG = g.toString(16).padStart(2, "0");
  const hexB = b.toString(16).padStart(2, "0");
  return `#${hexR}${hexG}${hexB}`;
}
