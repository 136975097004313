import { Toaster } from "@/components/ui/toaster";
import { ProvideAuth } from "@/lib/hooks/use-auth";
import { ProvideFetch } from "@/lib/hooks/use-fetch";
import { ProvideProperty } from "@/lib/hooks/use-property";
import { ProvideScreenBlur } from "@/lib/hooks/use-screenBlur";
import { ProvideSocketIo } from "@/lib/hooks/use-socketIo";
import Router from "components/Router";

import React from "react";
import { AppUpdater } from "./AppUpdater";
import ErrorBoundary from "./ErrorBoundary";

function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <ProvideFetch>
          <ProvideProperty>
            <ProvideAuth>
              <ProvideSocketIo>
                <ProvideScreenBlur>
                  <Router />
                </ProvideScreenBlur>
              </ProvideSocketIo>
            </ProvideAuth>
          </ProvideProperty>
        </ProvideFetch>
        <AppUpdater />
        <Toaster />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default App;
