import { getNextDateUTC } from "lib/datetime/calculations";
import { parseTime } from "lib/datetime/parsers";
import { useState } from "react";
import { useProperty } from "./use-property";

export const useCheckIn = ({
  user,
  reservation,
  roomUpgradeOptionsCount,
  earlyCheckInOptionsCount,
  petFeeOptionsCount,
  promosCount,
}) => {
  const { property } = useProperty();
  const checkInConfig = property?.appFeatures?.CheckIn || {};
  const [completedSteps, setCompletedSteps] = useState([]);

  const requirements = Object.keys(checkInConfig)
    .map((name) => {
      return {
        name: name,
        completed: isRequirementCompleted(name, {
          property,
          checkInConfig,
          user,
          reservation,
          roomUpgradeOptionsCount,
          earlyCheckInOptionsCount,
          petFeeOptionsCount,
          promosCount,
          completedSteps,
        }),
        canSkip: canSkipRequirement(name, { user, checkInConfig }),
        canModify: canModifyRequirmentData(name),
        config: checkInConfig[name],
      };
    })
    .sort((a, b) => {
      // order should be payment, identifcation pet, roomUpgrades, earlyCheckIn, promos, completeProfile
      if (a.name === "payment") return -1;
      if (b.name === "payment") return 1;
      if (a.name.startsWith("identification")) return -1;
      if (b.name.startsWith("identification")) return 1;
      if (a.name === "pet") return -1;
      if (b.name === "pet") return 1;
      if (a.name === "earlyCheckIn") return -1;
      if (b.name === "earlyCheckIn") return 1;
      if (a.name === "roomUpgrades") return -1;
      if (b.name === "roomUpgrades") return 1;
      if (a.name === "promo") return -1;
      if (b.name === "promo") return 1;
      if (a.name === "completeProfile") return -1;
      if (b.name === "completeProfile") return 1;

      return 0;
    });

  const currentStep = requirements.find((req) => {
    const { completed, canModify } = req;
    const isSkipped = completedSteps.includes(req.name);

    if (canModify && !isSkipped) return true;

    return !completed && !isSkipped;
  });

  const allCompleted = requirements.every(
    (req) => req.completed || completedSteps.includes(req.name),
  );

  return {
    currentStep: currentStep,
    checkInConfig: checkInConfig,
    requirements: requirements,
    allCompleted: allCompleted,
    skippedSteps: completedSteps,
    markComplete: (name) => {
      if (typeof name === "string") {
        setCompletedSteps([...completedSteps, name]);
      }

      if (Array.isArray(name)) {
        setCompletedSteps([...completedSteps, ...name]);
      }
    },
  };
};

function isRequirementCompleted(
  name,
  {
    property,
    user = {},
    reservation = {},
    roomUpgradeOptionsCount,
    earlyCheckInOptionsCount,
    petFeeOptionsCount,
    promosCount,
    checkInConfig,
    completedSteps = [],
  },
) {
  if (completedSteps.includes(name)) return true;
  const timezone = property?.timezone;
  const propertyCheckInDateTime = getNextDateUTC(
    new Date(reservation.checkInDateTime),
    {
      ...parseTime(property?.checkInTime),
      timezone,
    },
  );
  const isDemo = Boolean(property?.id === "demo");
  const reqConfig = checkInConfig[name];
  const checkInTimePast = new Date(reservation.checkInDateTime) < new Date();
  const hasEarlyCheckIn =
    new Date(reservation.checkInDateTime) < propertyCheckInDateTime;
  const categoryChanged = reservation.mobileCheckInSpaceCategoryChanged;

  const hasExpectedCheckInTime = Boolean(reservation.expectedCheckInDateTime);

  if (name.startsWith("identification")) {
    const types = reqConfig.types;
    const userIdDoc = user.identifications?.find(
      (id) => types.includes(id.type) && !id.isExpired,
    );
    if (!userIdDoc) return false;
    const requiresMatch = reqConfig.match;
    const requiresVerify = reqConfig.verify;
    if (requiresMatch) return userIdDoc.matched === true;
    if (requiresVerify) return userIdDoc.verified === true;
    return true;
  }

  switch (name) {
    case "earlyCheckIn": {
      if (isDemo) {
        return (
          hasExpectedCheckInTime &&
          new Date(reservation.checkInDateTime).getTime() <=
            new Date(reservation.expectedCheckInDateTime).getTime()
        );
      }
      if (checkInTimePast || hasEarlyCheckIn) return true;
      else {
        if (earlyCheckInOptionsCount > 0) return false;
        else return true;
      }
    }
    case "roomUpgrades": {
      if (isDemo) return false;
      if (checkInTimePast || categoryChanged) return true;
      if (roomUpgradeOptionsCount > 0) return false;
      return true;
    }
    case "payment": {
      return user.payments?.length > 0;
    }

    case "completeProfile": {
      return checkInTimePast || user?.photo !== undefined;
    }

    case "pet": {
      return petFeeOptionsCount === 0;
    }

    case "promo":
      return promosCount === 0;

    default:
      return true;
  }
}

function canSkipRequirement(name, { user = {} }) {
  switch (name) {
    case "earlyCheckIn":
    case "roomUpgrades":
    case "completeProfile":
    case "pet":
    case "promo":
      return true;

    case "payment":
      return user.payments?.length > 0;

    default:
      return false;
  }
}

function canModifyRequirmentData(name) {
  switch (name) {
    case "payment":
    case "earlyCheckIn":
    case "roomUpgrades":
      return true;
    default:
      return false;
  }
}
