export {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  isDate,
  isToday,
  isTomorrow,
  set,
  subDays,
} from "date-fns";

import { set as setDate } from "date-fns";
import {
  toZonedTime as convertUTCToZonedTime,
  fromZonedTime as convertZonedTimeToUTC,
} from "date-fns-tz";

export {
  convertUTCToZonedTime as utcToZonedTime,
  convertZonedTimeToUTC as zonedTimeToUtc,
};

export function getNextDateUTC(
  start = new Date(),
  { hours = 0, minutes = 0, seconds = 0, milliseconds = 0, timezone },
) {
  const zonedDate = convertUTCToZonedTime(start, timezone);

  return convertZonedTimeToUTC(
    setDate(zonedDate, {
      hours,
      minutes,
      seconds,
      milliseconds,
    }),
    timezone,
  );
}
export function midnightTodayAtTimezone(date, { timezone }) {
  let start = date;
  if (!start) start = new Date();
  if (timezone) start = convertUTCToZonedTime(start, timezone);

  let midnight = setDate(start, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  if (timezone) midnight = convertZonedTimeToUTC(midnight, timezone);

  return midnight;
}
export function midnightAtTimezone(date, timezone) {
  if (typeof date !== "string") throw new Error("date must be a string");
  if (typeof timezone !== "string")
    throw new Error("timezone must be a string");
  const parts = date.split("-");
  const year = Number(parts[0]);
  // subtract 1 from month because date-fns uses 0-indexed months
  const month = Number(parts[1]) - 1;
  const day = Number(parts[2]);

  const zonedDate = convertUTCToZonedTime(new Date(), timezone);

  const zoned = setDate(zonedDate, {
    year,
    month,
    date: day,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return convertZonedTimeToUTC(zoned, timezone);
}

export function getTimeSince(startDate = new Date(), endDate) {
  if (!endDate) return null;
  // get display distance as Hours:Minutes:Seconds
  const distance = Math.abs(new Date(startDate) - new Date(endDate));
  let hours = Math.floor(distance / 3600000);
  let minutes = Math.floor((distance % 3600000) / 60000);
  let seconds = Math.floor((distance % 60000) / 1000);

  // make sure hours, minutes, and seconds are two digits
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}
