export function parse24HourTimeString(string) {
  const [hours, minutes] = string.split(":");
  return {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
}
export function parseTime(string) {
  // convert 4:00 PM to 16:00
  const [time, modifier] = string.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
}

export { parse } from "date-fns";
